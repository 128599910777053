import React, { FC } from "react";
import { MainLayout } from "@/layouts/MainLayout";
import Helmet from "react-helmet";
import { SEO } from "@/components/basic/SEO";
import { Link } from "@/components/basic/Link";
import { SiteFooterWrapper } from "@/components/basic/SiteFooterWrapper";

const TermsOfService: FC = () => (
  <MainLayout>
    <Helmet>
      <body className="ltr terms-of-service" id="intro" />
    </Helmet>
    <SEO
      title="ANIXE Polska sp. z o.o. terms of service"
      description="ANIXE is a leading travel technology provider specialized in third-party hotel
      supplier aggregation and distribution, accelerated search and response, offline rate and availability exports
      in various formats, hotel and room type automated mapping and high data load and traffic management."
    />
    <section className="hero-section hero-section-terms-of-service color-bg-light">
      <div className="l-wrapper clearfix">
        <div className="l-8-cols-tablet">
          <h1 className="color-primary">Terms of Service</h1>
          <h3>Regulations for the Provision of Electronic Services</h3>
        </div>
      </div>
    </section>
    <div className="l-wrapper color-bg-negative clearfix text-doc">
      <div className="l-8-cols-desktop l-with-dbl-gutter-top-mobile l-island clearfix">
        <p className="align-center">
          <b>§ 1 General Provisions</b>
        </p>
        <ol className="l-push-bottom l-with-dbl-gutter-bottom-mobile">
          <li>
            These Regulations issued on the basis of the Act of 18 July 2002 on
            Rendering Electronic Services (i.e., Journal of Laws of 2019, item
            123 with subsequent changes) (the <strong>“Act”</strong>) specify
            types and scope of the electronic services provided by{" "}
            <strong>ANIXE Polska Sp. z o.o.</strong>, conditions of the
            provision of such services, conditions of conclusion and termination
            of agreements on provision of electronic services, and a complaint
            procedure, pursuant to the law of Republic of Poland.
          </li>
          <li>
            Access to the https://anixe.io/ website (the “Website”) is subject
            to these Regulations as applicable at the time of use. These
            Regulations are made available on the Website in the „Terms of
            Service” section, free of charge, in a form allowing for free
            recording, storage and reproduction by the User as defined below.
            The content of these Regulations may be obtained, reproduced and
            recorded by the User with any technique and media for purposes
            related to the use of this Website and the services provided by
            means of this Website.
          </li>
          <li>
            The Service Provider renders electronic services through the
            Website.
          </li>
        </ol>
        <p className="align-center l-dbl-push-top">
          <b>§ 2 Definitions</b>
        </p>
        <p>
          For the purpose of these Regulations the following definitions will
          apply:
        </p>
        <ol className="l-push-bottom l-with-dbl-gutter-bottom-mobile">
          <li>
            Service Provider / we / our / us – ANIXE Polska Sp. z o.o. seated at
            ul. Grabiszynska 251a, 53- 234 Wroclaw, Poland, entered into the
            register of entrepreneurs of the National Court Register maintained
            by the District Court for Wrocław-Fabryczna in Wrocław, VI Division
            of the National Court Register, under KRS number 0000008486, NIP
            899240948, REGON 932650883, with the share capital of PLN
            105.000,00, e-mail address{" "}
            <a href="mailto:privacy@anixe.pl">privacy@anixe.pl</a>.
          </li>
          <li>
            User – any person (natural or legal, as well as an organizational
            unit without legal personality) using the Website.
          </li>
          <li>
            Website – website maintained by the Service Provider, operating
            under the address https://anixe.io/.
          </li>
          <li>
            Regulations – current version of these Regulations, providing terms
            of use of the Website, available on the Website in the „Terms of
            Service” section.
          </li>
          <li>
            Personal Data – any information referring to the User, collected
            during a visit on our Website, or while contacting us by means of
            the tools available on the Website, depending on type of service
            provided (see § 5). To obtain more information on personal data we
            collect during User’s visit on the Website and while using our ATS
            (Applicant Tracking System) please read our Privacy Polices
            respectively for the Polish and Greek market, links to which may be
            found at the bottom of our Website.
          </li>
        </ol>
        <p className="align-center">
          <b>§ 3 Terms of Use of the Website</b>
        </p>
        <ol className="l-push-bottom l-with-dbl-gutter-bottom-mobile">
          <li>
            The Website operates under the terms described in the Regulations.
          </li>
          <li>
            Using the Website is free of charge, voluntary and allowed for all
            Users using the Internet.
          </li>
          <li>
            Making use of the Website means that a given User accepts the
            provisions of these Regulations.
          </li>
          <li>
            The Website uses User’s cookies. Please read our “Privacy and Cookie
            Policy” available on the Website, to obtain more information on
            types of cookies we use, as well as their purposes and actions to be
            taken in order to prevent the storage of cookies on his/her device.
          </li>
          <li>
            In order to use the Website correctly, the User needs to install a
            web browser such as for example Explorer, Google Chrome or Mozilla
            Firefox, on a device with Internet access.
          </li>
        </ol>
        <p className="align-center">
          <b>§ 4 User’s Rights and Obligations</b>
        </p>
        <ol className="l-push-bottom l-with-dbl-gutter-bottom-mobile">
          <li>
            Each User is obligated to use the Website as required by these
            Regulations, the provisions of law and good practice.
          </li>
          <li>
            In particular the following actions are prohibited:
            <ol>
              <li>
                providing false, imprecise or outdated information or additional
                personal data not required by the contact forms available on the
                Website, during the visit on the Website;
              </li>
              <li>
                spreading false information or information that is against the
                law regarding the content of the Website;
              </li>
              <li>
                violating in any way personal interests of the Service Provider,
                its employees, affiliates and third persons, as well as any
                other persons’ rights, including intellectual property rights;
              </li>
              <li>delivering content which breaches any provisions of law;</li>
              <li>
                interfering in the Website’s functioning, including changes to
                the Website’s code, using or placing viruses, worms, Trojans,
                unauthorized extensions and any other mechanism that may
                adversely affect functioning of the Website or the software or
                the Service Provider’s or other Users’ devices.
              </li>
            </ol>
          </li>
        </ol>
        <p className="align-center">
          <b>§ 5 Types and Scope of the Services</b>
        </p>
        <ol className="l-push-bottom l-with-dbl-gutter-bottom-mobile">
          <li>
            The Service Provider provides the following electronic services via
            the Website:
            <ol>
              <li>
                handling the User’s request to receive our newsletter, sent via
                the relevant form;
              </li>
              <li>
                handling the User’s request for contact concerning our products,
                sent via the contact form;
              </li>
              <li>
                handling the User’s request for preparing ANIXE’s Resfinity
                platform Demo, sent via the relevant form;
              </li>
              <li>
                handling the User’s request for receiving a Product Card, sent
                via the relevant form;
              </li>
              <li>
                directing the User to the{" "}
                <a href="https://anixe.bamboohr.com/jobs/">
                  https://anixe.bamboohr.com/jobs/
                </a>{" "}
                website, which allows sending job applications.
              </li>
            </ol>
          </li>
          <li>
            The Services listed in point 1 section a) – d) above will be
            provided through relevant forms available on the Service Provider’s
            Website.
          </li>
          <li>
            The job application service, as referred to by point 1 section e)
            above is administered by Bamboo HR (our HR solutions provider),
            provided through and is protected by reCAPTCHA and the Google
            Privacy Policy (available at{" "}
            <a href="https://policies.google.com/privacy">
              https://policies.google.com/privacy
            </a>{" "}
            and Terms of Service (available at{" "}
            <a href="https://policies.google.com/terms">
              https://policies.google.com/terms
            </a>
            ) apply.
          </li>
        </ol>
        <p className="align-center">
          <b>
            § 6 Terms of Provision, Conclusion and Termination of the Agreement
            on Provision of Electronic Services
          </b>
        </p>
        <ol className="l-push-bottom l-with-dbl-gutter-bottom-mobile">
          <li>
            The User may use the Website anonymously up until providing data
            required by forms (marked with asterisk) to use services, as
            described in § 5 point 1.
          </li>
          <li>
            To place an order for services, the User is required to give
            information marked with asterisk in the selected form.
          </li>
          <li>
            The provision of services is free of charge. The agreement on
            provision of services shall be concluded upon sending the relevant
            form (in case of services mentioned in § 5 point 1 section a) – d)
            or choosing “CAREER” section (in case of service mentioned in § 5
            point 1 section e).
          </li>
          <li>
            In relation to § 5 point 1 section b) – e) the provision of services
            shall be completed, and the agreement on provision of services
            terminated, upon completing the request for contact or directing the
            User to the job application website.
          </li>
          <li>
            In relation to § 5 point 1 section a) the agreement on provision of
            services is concluded for an indefinite period. The User may decide
            to unsubscribe from newsletter services at any time, which shall be
            equal to terminating the agreement. Similarly, we may decide to stop
            providing newsletter services for whatever reason which also shall
            be equal to terminating the agreement.
          </li>
          <li>
            The User will be responsible for the accuracy of the data provided.
          </li>
          <li>
            The Service Provider will not be responsible for any false,
            incorrect or incomplete information provided by the User, especially
            if the User provides third persons’ data without their consent or
            knowledge. The User using the Website will be responsible for any
            consequences of providing incorrect, false, incomplete or misleading
            data.
          </li>
          <li>
            The Service Provider will not be responsible for the consequences of
            the User’s use of the services in the manner contrary to these
            Regulations.
          </li>
        </ol>
        <p className="align-center">
          <b>§ 7 Complaint Procedure</b>
        </p>
        <ol className="l-push-bottom l-with-dbl-gutter-bottom-mobile">
          <li>
            The Service Provider will use its best efforts to ensure proper
            functioning of the Website.
          </li>
          <li>
            The User, who made use of the Services described in § 5 point 1 of
            the Regulations will have the right to submit a complaint concerning
            both the technical aspects of their functioning as well as other
            aspects, by sending a complaint to the address:{" "}
            <a href="mailto:privacy@anixe.pl">privacy@anixe.pl</a> or the
            address of the Service Provider’s seat provided in § 2 point 1.
          </li>
          <li>
            The complaint should be submitted no later than within 14 (fourteen)
            days from the day on which the cause of the complaint occurred.
          </li>
          <li>
            Each complaint should contain a detailed description of the problem
            being the basis of submitting the complaint, as well as the date and
            hour of its occurrence, and the User’s contact details allowing the
            answer to the complaint or the reasons why it is not possible to
            provide such information.
          </li>
          <li>
            The Service Provider may ask the User to provide additional
            information necessary to respond.
          </li>
          <li>
            The Service Provider will use its best efforts to consider
            complaints within 30 (thirty) days from receipt of complete
            information. The Service Provider will notify the User of the
            outcome of the complaint immediately, by electronic mail or by
            regular mail, as chosen by the User.
          </li>
          <li>
            Complaints that do not contain all the data listed in point 4 above
            or those submitted after the deadline referred to in point 3 above
            may not be considered.
          </li>
        </ol>
        <p className="align-center">
          <b>§ 8 Responsibility</b>
        </p>
        <ol className="l-push-bottom l-with-dbl-gutter-bottom-mobile">
          <li>
            Due to technical reasons some malfunctions or failures preventing or
            hindering use of the Website may occur. This also includes temporary
            deactivation of access to the Website and its services while their
            upgrading.
          </li>
          <li>
            The Service Provider shall not be liable for malfunctions or
            unavailability of the Website due to the above mentioned reasons or
            other reasons beyond the Service Provider’s control.
          </li>
        </ol>
        <p className="align-center">
          <b>§ 9 Change of the Regulations</b>
        </p>
        <ol className="l-push-bottom l-with-dbl-gutter-bottom-mobile">
          <li>
            The Service Provider reserves the right to change these Regulations.
          </li>
          <li>
            Change of the Regulations will become effective upon posting new
            Regulations on the Website or on the day indicated in a notice
            placed on the Website, notifying of such change.
          </li>
          <li>Current Regulations are always available on the Website.</li>
        </ol>
        <p className="align-center">
          <b>
            § 10 Information on Specific Risks Related to the Use of the
            Electronic Services Provided by the Service Provider
          </b>
        </p>
        <ol className="l-push-bottom l-with-dbl-gutter-bottom-mobile">
          <li>
            The Service Provider, fulfilling its obligation under Article 6
            point 1 of the Act, informs about specific risks related to the use
            of Services. This information regards risks that may only occur
            potentially but should be considered despite the Service Provider’s
            applying measures protecting the Website from unauthorized actions
            by third parties.
          </li>
          <li>
            The basic risks related to the use of the Internet are as follows:
            <ol>
              <li>
                malware – various types of applications or scripts having
                harmful, criminal or malicious effect on the network user’s
                information system, such as viruses, worms, Trojans (Trojan
                horses), key loggers, dialers;
              </li>
              <li>
                spyware – programs that track user’s actions, collect
                information about user and send it, usually without user’s
                knowledge and consent, to the author of the program;
              </li>
              <li>
                spam – unwanted and unsolicited electronic messages sent to
                multiple recipients at the same time, often containing
                advertising content;
              </li>
              <li>
                phishing – fraudulent use of confidential personal information
                (e.g., passwords) by impersonating a trustworthy person or
                institution;
              </li>
              <li>
                hacking into the user’s information system, using such hacking
                tools as exploit and rootkit.
              </li>
            </ol>
          </li>
          <li>
            In order to avoid the above risks, the User should install an
            antivirus program on his/her computer and other electronic devices
            that use the Internet. Such program should always be updated.
          </li>
          <li>
            The following measures also enhance the protection against risks
            related to the use of the Website and all of its functionalities:
            <ol>
              <li>making sure the firewall is on;</li>
              <li>updating of the software used;</li>
              <li>non-opening of e-mail attachments of unknown origin;</li>
              <li>
                reading application installation windows, and their licenses;
              </li>
              <li>disabling macros in MS Office files of unknown origin;</li>
              <li>
                regular comprehensive scans of the system with antivirus and
                antimalware software;
              </li>
              <li>encrypting data transmission;</li>
              <li>
                installing prevention programs (detecting and preventing
                hacking);
              </li>
              <li>
                using original system and applications from a legal source.
              </li>
            </ol>
          </li>
        </ol>
        <p className="align-center">
          <b>§ 11 Final Provisions</b>
        </p>
        <ol className="l-push-bottom l-with-dbl-gutter-bottom-mobile">
          <li>
            These Regulations shall be subject to provisions of Polish law.
          </li>
          <li>
            In any matters not regulated by the provisions of these Regulations
            relevant provisions will apply, in particular the Act and the Civil
            Code of 23 April 1964 (Journal of Laws of 2019, item 1145 with
            subsequent amendments).
          </li>
          <li>
            The Service Provider’s Website contains contents protected by
            copyright, industrial property law, and intangible assets protected
            by the intellectual property law. None of this content, especially
            graphics, text, trademarks, logotypes, icons, photos, programs
            presented on the Website may be reproduced or distributed in any
            form or by any manner without a prior written consent of the Service
            Provider.
          </li>
        </ol>
      </div>
    </div>
    <SiteFooterWrapper>
      <Link
        classname="button button-quaternary-negative-dark button-estimate-project"
        url="/estimate-project"
        text="estimate project"
      />
      <Link
        classname="button button-primary button-request-demo"
        url="/demo-form"
        text="request a demo"
      />
    </SiteFooterWrapper>
  </MainLayout>
);

export default TermsOfService;
